const GOLD_SUNSET_FAQ = 'https://help.wizardingworld.com/hc/en-us/articles/4405440548756';

/* istanbul ignore file */
const redirectMap = {
  '/quidditch-world-cup/history': `/writing-by-jk-rowling/history-of-the-quidditch-world-cup`,
  '/quidditch-world-cup': `/writing-by-jk-rowling/history-of-the-quidditch-world-cup`,
  '/Harry-Potter-and-the-Philosophers-Stone-Jim-Kay': '/',
  '/image/snapes-potions-class': `/writing-by-jk-rowling/potions`,
  '/image/ollivanders-shop': `/news/discover-your-wand-on-pottermore`,
  '/image/a-lesson-on-the-unforgivable-curses': `/features/what-is-the-worst-spell-from-the-wizarding-world`,
  '/image/patronus-summoned-by-luna-lovegood': `/features/what-can-a-patronus-say-about-a-character`,
  '/image/aberforth-patronus': `/features/unsung-heroes-of-harry-potter-stories-aberforth-dumbledore`,
  '/image/the-wand-chooses-the-wizard': `/features/how-loyal-is-a-wand-to-a-wizard`,
  '/image/dobby-is-a-free-elf': `/features/heroic-house-elves-of-the-wizarding-world`,
  '/image/moony-wormtail-padfoot-prongs': `/features/everything-we-know-about-the-marauders`,
  '/image/heir-of-slytherin': `/features/all-the-myths-that-were-debunked-in-chamber-of-secrets`,
  '/image/fawkes-the-phoenix': `/features/32-thoughts-fawkes-might-have-had-during-chamber-of-secrets`,
  '/collection-episodic/wizarding-schools': `/writing-by-jk-rowling/wizarding-schools`,
  '/collection-episodic/the-art-of-wandmaking-by-jkr': `/writing-by-jk-rowling/mr-ollivander`,
  '/collection-episodic/history-of-magic-in-north-america-ua': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-ua`,
  '/collection-episodic/history-of-magic-in-north-america-tr': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-tr`,
  '/collection-episodic/history-of-magic-in-north-america-th': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-th`,
  '/collection-episodic/history-of-magic-in-north-america-spa': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-spa`,
  '/collection-episodic/history-of-magic-in-north-america-sk': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-sk`,
  '/collection-episodic/history-of-magic-in-north-america-si': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-si`,
  '/collection-episodic/history-of-magic-in-north-america-se': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-se`,
  '/collection-episodic/history-of-magic-in-north-america-ru': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-ru`,
  '/collection-episodic/history-of-magic-in-north-america-rs': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-rs`,
  '/collection-episodic/history-of-magic-in-north-america-ro': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-ro`,
  '/collection-episodic/history-of-magic-in-north-america-pt': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-pt`,
  '/collection-episodic/history-of-magic-in-north-america-pl': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-pl`,
  '/collection-episodic/history-of-magic-in-north-america-no': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-no`,
  '/collection-episodic/history-of-magic-in-north-america-nl': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-nl`,
  '/collection-episodic/history-of-magic-in-north-america-lv': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-lv`,
  '/collection-episodic/history-of-magic-in-north-america-lt': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-lt`,
  '/collection-episodic/history-of-magic-in-north-america-kr': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-kr`,
  '/collection-episodic/history-of-magic-in-north-america-jp': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-jp`,
  '/collection-episodic/history-of-magic-in-north-america-it': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-it`,
  '/collection-episodic/history-of-magic-in-north-america-id': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-id`,
  '/collection-episodic/history-of-magic-in-north-america-hu': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-hu`,
  '/collection-episodic/history-of-magic-in-north-america-hr': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-hr`,
  '/collection-episodic/history-of-magic-in-north-america-hk': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-hk`,
  '/collection-episodic/history-of-magic-in-north-america-gr': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-gr`,
  '/collection-episodic/history-of-magic-in-north-america-fr': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-fr`,
  '/collection-episodic/history-of-magic-in-north-america-fi': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-fi`,
  '/collection-episodic/history-of-magic-in-north-america-es': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-es`,
  '/collection-episodic/history-of-magic-in-north-america-en': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-en`,
  '/collection-episodic/history-of-magic-in-north-america-ee': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-ee`,
  '/collection-episodic/history-of-magic-in-north-america-dk': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-dk`,
  '/collection-episodic/history-of-magic-in-north-america-de': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-de`,
  '/collection-episodic/history-of-magic-in-north-america-cz': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-cz`,
  '/collection-episodic/history-of-magic-in-north-america-cn': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-cn`,
  '/collection-episodic/history-of-magic-in-north-america-br': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-br`,
  '/collection-episodic/history-of-magic-in-north-america-bg': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-bg`,
  '/collection-episodic/history-of-magic-in-north-america-be': `/writing-by-jk-rowling/fourteenth-century-to-seventeenth-century-be`,
  '/collection-episodic/harry-potters-villains-by-jkr': `/writing-by-jk-rowling/dolores-umbridge`,
  '/collection-episodic/draco-and-the-malfoys': `/writing-by-jk-rowling/draco-malfoy`,
  '/gold-purchase-terms-us': '/terms/gold-purchase-terms-us',
  '/gold-purchase-terms-uk': '/terms/gold-purchase-terms-uk',
  [`/news/introducing-wizarding-world-gold-subscription`]: `/about-gold?utm_source=Direct`,
  [`/news/funko-pop-shop-link`]: `/store?path=/products/exclusive-pre-release-harry-potter-patronus-funko-pop-vinyl-figure`,
  [`/news/hermione-patronus-funko-pop-announcement`]: `/store?path=/products/exclusive-pre-release-hermione-granger-patronus-funko-pop-vinyl-figure`,
  [`/news/discover-your-hogwarts-house-on-pottermore`]: `/news/discover-your-hogwarts-house-on-wizarding-world`,
  '/story-time': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/news/behind-the-scenes-the-shrieking-shack': '/news',
  '/news/behind-the-scenes-the-basilisk': '/news',
  '/news/a-closer-look-at-the-characters-of-fantastic-beasts-the-crimes-of-grindelwald': '/news',
  '/news/behind-the-scenes-at-the-battle-of-hogwarts': '/news',
  '/news/behind-the-scenes-mary-lou-barebone': '/news',
  '/news/sayre-family-tree': '/news',
  '/news/gaunt-family-tree': '/news',
  '/news/black-family-tree': '/news',
  '/news/behind-the-scenes-werewolves': '/news',
  '/news/behind-the-scenes-trolls': '/news',
  '/news/behind-the-scenes-grimmauld-place': '/news',
  '/news/behind-the-scenes-lucius-and-narcissa-malfoy': '/news',
  '/news/behind-the-scenes-inferi': '/news',
  '/news/behind-the-scenes-fantastic-beasts-nifflers': '/news',
  '/news/behind-the-scenes-platform-nine-and-three-quarters': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-credence': '/news',
  '/news/behind-the-scenes-the-forbidden-forest': '/news',
  '/news/wizarding-world-book-o-graphic': '/news',
  '/news/behind-the-scenes-argus-filch': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-inside-newts-shed': '/news',
  '/news/behind-the-scenes-professor-quirrell': '/news',
  '/news/the-weasley-family-tree': '/news',
  '/news/behind-the-scenes-cornish-pixies': '/news',
  '/news/the-definitive-guide-to-the-order-of-the-phoenix-and-dumbledores-army-infographic':
    '/news',
  '/news/behind-the-scenes-budleigh-babberton': '/news',
  '/news/behind-the-scenes-malfoy-manor': '/news',
  '/news/behind-the-scenes-madam-pomfrey': '/news',
  '/news/behind-the-scenes-giants': '/news',
  '/news/behind-the-scenes-designing-fawkes': '/news',
  '/news/behind-the-scenes-professor-sprout': '/news',
  '/news/a-guide-to-lord-voldemorts-death-eaters': '/news',
  '/news/care-of-magical-creatures-illustrations-winged-horses': '/news',
  '/news/behind-the-scenes-hogsmeade': '/news',
  '/news/wonderful-wizarding-world-happiness-generator': '/news',
  '/news/behind-the-scenes-professor-trelawney': '/news',
  '/news/behind-the-scenes-designing-diagon-alley-from-scratch': '/news',
  '/news/behind-the-scenes-the-leaky-cauldron': '/news',
  '/news/behind-the-scenes-mandrakes': '/news',
  '/news/behind-the-scenes-moving-staircases': '/news',
  '/news/behind-the-scenes-grindylows': '/news',
  '/news/behind-the-scenes-defence-against-the-dark-arts-classroom': '/news',
  '/news/behind-the-scenes-whomping-willow': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-newt-scamander': '/news',
  '/news/the-life-and-times-of-albus-dumbledore': '/news',
  '/news/behind-the-scenes-nymphadora-tonks': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-the-diamond-district': '/news',
  '/news/behind-the-scenes-kingsley-shacklebolt': '/news',
  '/news/behind-the-scenes-thestrals': '/news',
  '/news/behind-the-scenes-peter-pettigrew': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-wanted-posters': '/news',
  '/news/behind-the-scenes-professor-flitwick': '/news',
  '/news/behind-the-scenes-fred-and-george-weasley': '/news',
  '/news/behind-the-scenes-designing-the-props-for-fantastic-beasts-and-where-to-find-them':
    '/news',
  '/news/the-malfoy-family-tree': '/news',
  '/news/behind-the-scenes-deathly-hallows': '/news',
  '/news/behind-the-scenes-centaurs': '/news',
  '/news/behind-the-scenes-acromantula': '/news',
  '/news/behind-the-scenes-lovegood-house': '/news',
  '/news/behind-the-scenes-horace-slughorn': '/news',
  '/news/behind-the-scenes-boggarts': '/news',
  '/news/behind-the-scenes-mimbulus-mimbletonia': '/news',
  '/news/behind-the-scenes-knight-bus': '/news',
  '/news/behind-the-scenes-animagus': '/news',
  '/news/behind-the-scenes-professor-lockhart': '/news',
  '/news/the-potter-family-tree': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-the-wands-of-the-characters': '/news',
  '/news/behind-the-scenes-the-burrow': '/news',
  '/news/behind-the-scenes-spinners-end': '/news',
  '/news/behind-the-scenes-xenophilius-lovegood': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-bowtruckles': '/news',
  '/news/behind-the-scenes-cornelius-fudge': '/news',
  '/news/christmas-corner-2017': '/news',
  '/news/behind-the-scenes-fantastic-beast-newts-wand': '/news',
  '/news/room-of-requirement-infographic': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-thunderbirds': '/news',
  '/news/behind-the-scenes-marge-dursley': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-queenie-goldstein': '/news',
  '/news/behind-the-scenes-the-ghosts-of-hogwarts': '/news',
  '/news/the-longbottom-family-tree': '/news',
  '/news/behind-the-scenes-fantastic-beasts-the-blind-pig': '/news',
  '/news/behind-the-scenes-at-the-yule-ball': '/news',
  '/news/behind-the-scenes-little-hangleton-graveyard': '/news',
  '/news/behind-the-scenes-designing-giant-wizard-chess': '/news',
  '/news/gellert-grindelwald-the-story-so-far': '/news',
  '/news/behind-the-scenes-rita-skeeter': '/news',
  '/news/behind-the-scenes-james-and-lily-potter': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-gnarlak': '/news',
  '/news/behind-the-scenes-gringotts': '/news',
  '/news/behind-the-scenes-hippogriffs': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-and-where-find-them-times-square-in-the-1920s':
    '/news',
  '/news/behind-the-scenes-the-horcruxes': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-macusa': '/news',
  '/news/behind-the-scenes-dementors': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-tina-goldstein': '/news',
  '/news/behind-the-scenes-honeydukes': '/news',
  '/news/behind-the-scenes-the-hogs-head': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-jacob-kowalski': '/news',
  '/news/behind-the-scenes-of-fantastic-beasts-modesty-and-chastity-barebone': '/news',
  '/news/behind-the-scenes-post-owls': '/news',
  '/news/behind-the-scenes-madam-hooch': '/news',
  '/news/fantastic-beasts-dan-fogler-eddie-redmayne-interview': '/news',
  '/news/the-emotional-last-days-of-filming-fantastic-beasts': '/news',
  '/news/fantastic-beasts-interview-series-eddie-redmayne': '/news',
  '/news/the-british-library-reveals-details-on-harry-potter-a-history-of-magic-exhibition':
    '/news',
  '/news/fantastic-beasts-the-crimes-of-grindelwald-to-have-its-world-premiere-in-paris': '/news',
  '/news/san-diego-comic-con-2016-with-cast-of-fantastic-beasts': '/news',
  '/news/new-items-revealed-from-british-library-harry-potter-exhibition-including-jk-rowling-sorting-hat-song-draft':
    '/news',
  '/news/jack-thorne-reacts-to-harry-potter-and-the-cursed-childs-best-play-win': '/news',
  '/news/cursed-child-to-open-in-germany-in-2020': '/news',
  '/news/pottermore-sorcerers-stone-live-at-hollywood-bowl': '/news',
  '/news/pottermore-meets-stars-at-wizarding-world-hollywood': '/news',
  '/news/british-library-curators-pick-their-favourite-rooms-in-harry-potter-a-history-of-magic':
    '/news',
  '/news/fantastic-beasts-inside-goldstein-sisters-apartment': '/news',
  '/news/remembering-john-hurt': '/news',
  '/news/fans-tweet-their-favourite-memories-at-celebration-of-harry-potter': '/news',
  '/news/harry-potter-comes-to-lego-dimensions': '/news',
  '/news/fantastic-beasts-interview-series-carmen-ejogo': '/news',
  '/news/harry-potter-aragog-creature-designer-nick-dudman-bad-with-spiders': '/news',
  '/news/jim-kay-speaks-at-house-of-illustration': '/news',
  '/news/cursed-child-wins-eight-whatsonstage-awards': '/news',
  '/news/behind-the-scenes-with-cursed-child-costume-designer-katrina-lindsay': '/news',
  '/news/jk-rowling-and-bonnie-wright-challenge-us-to-spread-the-word-about-lumos': '/news',
  '/news/explore-the-darkness-of-the-wizarding-world-this-halloween-at-warner-bros-studio-tour-london':
    '/news',
  '/news/harry-potter-and-the-cursed-child-wins-record-breaking-nine-2017-olivier-awards': '/news',
  '/news/exclusive-interview-fantastic-beasts-cast-san-diego-comic-con-2016': '/news',
  '/news/fans-dress-up-for-halloween-at-warner-bros-studio-tour-london': '/news',
  '/news/first-look-at-the-australian-cursed-child-production-full-company': '/news',
  '/news/anything-is-possible-say-cursed-child-producers': '/news',
  '/news/would-you-like-to-dine-in-the-great-hall': '/news',
  '/news/fantastic-beasts-interview-series-alison-sudol': '/news',
  '/news/tickets-for-harry-potter-a-history-of-magic-in-new-york-coming-soon': '/news',
  '/news/win-tickets-for-lumos-fantastic-beasts-advance-screening-in-new-york': '/news',
  '/news/video-rupert-grint-sorting-interview': '/news',
  '/news/behind-the-scenes-of-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/news',
  '/news/8-exciting-clues-from-the-fantastic-beasts-featurette': '/news',
  '/news/pmc-celebrating-the-journey-to-the-eighth-harry-potter-story': '/news',
  '/news/the-best-fan-photos-from-celebration-of-harry-potter-2017': '/news',
  '/news/exclusive-video-fantastic-beasts-cast-on-working-with-the-harry-potter-creative-team':
    '/news',
  '/news/over-10000-tickets-to-be-released-for-cursed-child-on-broadway': '/news',
  '/news/fantastic-beasts-interview-series-david-yates': '/news',
  '/news/wand-attractions-open-at-wizarding-world-harry-potter-japan': '/news',
  '/news/video-matthew-lewis-sorting-interview': '/news',
  '/news/cursed-child-london-cast-visit-the-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/news',
  '/news/jk-rowling-attends-fantastic-beasts-world-premiere-in-new-york': '/news',
  '/news/all-the-wizarding-world-action-from-san-diego-comic-con-2019': '/news',
  '/news/harry-potter-a-history-of-magic-us-companion-book-cover-revealed': '/news',
  '/news/harry-potter-film-concert-series-uk-tour-interview-with-justin-freer': '/news',
  '/news/why-fantastic-beasts-graphic-artists-minalima-love-twenties-design': '/news',
  '/news/warner-bros-studio-tour-breakfast-at-hogwarts': '/news',
  '/news/the-day-pottermore-stepped-inside-newt-scamanders-magical-case': '/news',
  '/news/happy-anniversary-cursed-child-everything-you-need-to-know-about-the-latest-productions':
    '/news',
  '/news/british-library-exhibition-harry-potter-a-history-of-magic-to-open-in-new-york-next-year':
    '/news',
  '/news/cover-revealed-for-harry-potter-and-the-cursed-child-the-journey': '/news',
  '/news/jason-isaacs-on-bully-lucius-malfoy': '/news',
  '/news/im-a-gryffindor-but-i-kinda-wanted-to-be-a-slytherin-interview-with-imogen-heap': '/news',
  '/news/wizarding-wardrobes-opens-at-warner-bros-studio-tour-london': '/news',
  '/news/100-days-until-fantastic-beasts-and-where-to-find-them-hits-cinemas': '/news',
  '/news/from-the-red-carpet-harry-potter-and-the-cursed-child': '/news',
  '/news/fantastic-beasts-the-crimes-of-grindelwald-is-out-today': '/news',
  '/news/report-from-waterstones-piccadilly-cursed-child-book-launch': '/news',
  '/news/harry-potter-the-exhibition-now-open-in-germany': '/news',
  '/news/crimes-of-grindelwald-cast-surprise-fans-at-alabama-school': '/news',
  '/news/how-to-register-for-tickets-to-see-harry-potter-and-the-cursed-child-on-broadway': '/news',
  '/news/house-of-minalima-has-a-new-fantastic-beasts-the-crimes-of-grindelwald-exhibition':
    '/news',
  '/news/first-look-at-new-west-end-cast-for-harry-potter-and-the-cursed-child': '/news',
  '/news/minalima-interview-pottermore-presents-covers': '/news',
  '/news/what-wizarding-world-jobs-would-the-harry-potter-cast-members-have': '/news',
  '/news/fantastic-beasts-dog-teddy-redmayne': '/news',
  '/news/new-quidditch-through-the-ages-audiobook-launches-in-london': '/news',
  '/news/watch-the-final-fantastic-beasts-trailer': '/news',
  '/news/fantastic-beasts-movie-films-in-liverpool': '/news',
  '/news/harry-potter-and-the-prisoner-of-azkaban-illustrated-edition-goes-on-sale-today': '/news',
  '/news/how-is-hogwarts-league-faring-ahead-of-sport-relief-week': '/news',
  '/news/jk-rowling-takes-us-inside-cursed-child-rehearsals': '/news',
  '/news/new-sport-relief-app-challenges-harry-potter-fans-to-join-billion-steps-challenge':
    '/news',
  '/news/exclusive-look-at-the-harry-potter-colouring-book-out-today': '/news',
  '/news/harry-potter-fantastic-beasts-coming-to-lego-dimensions': '/news',
  '/news/all-the-action-from-the-fantastic-beasts-the-crimes-of-grindelwald-comic-con-panel':
    '/news',
  '/news/eddie-redmayne-on-recording-newt-scamanders-life-work': '/news',
  '/news/warner-bros-studio-tour-london-unveils-new-behind-the-seams-experience': '/news',
  '/news/fantastic-beasts-sneak-peek-at-celebration-of-harry-potter': '/news',
  '/news/make-christmas-extra-festive-this-year-with-these-wizarding-world-albums': '/news',
  '/news/j-k-rowling-welcome-message': '/news',
  '/news/watch-andrew-lincoln-discuss-his-quidditch-through-the-ages-audiobook': '/news',
  '/news/fantastic-beasts-carmen-ejogo-seraphina-interview': '/news',
  '/news/harry-potter-fans-gather-at-kings-cross-to-celebrate-going-back-to-hogwarts': '/news',
  '/news/a-day-in-the-life-of-a-fantastic-beasts-extra': '/news',
  '/news/jk-rowling-to-launch-new-lumos-campaign-with-live-interview': '/news',
  '/news/lumos-new-york-screening-fantastic-beasts': '/news',
  '/news/pmc-was-cast-as-a-no-maj-extra-in-fantastic-beasts': '/news',
  '/news/history-of-magic-in-north-america-starts-today-on-pottermore': '/news',
  '/news/fans-flock-to-kings-cross-to-celebrate-20-years-of-harry-potter-and-the-philosophers-stone':
    '/news',
  '/news/christmas-comes-to-warner-bros-studio-tour-london': '/news',
  '/news/bonnie-wright-is-the-latest-guest-added-to-a-celebration-of-harry-potter-2018': '/news',
  '/news/goblet-of-fire-coming-to-warner-bros-studio-tour-london': '/news',
  '/news/fantastic-beasts-eddie-redmayne-interview-mtv-movie-awards': '/news',
  '/news/designer-reveals-how-she-colour-coded-the-new-pottermore': '/news',
  '/news/win-tickets-for-fantastic-beasts-crimes-of-grindelwald-premiere-with-lumos': '/news',
  '/news/all-the-action-from-new-york-comic-con-2018': '/news',
  '/news/warner-bros-studio-tour-london-announce-new-forbidden-forest-attraction': '/news',
  '/news/cursed-child-weekly-ticket-release': '/news',
  '/news/san-diego-comic-con-2016-harry-potter-cosplayers': '/news',
  '/news/jk-rowling-and-fantastic-beasts-cast-at-european-premiere': '/news',
  '/news/more-tickets-for-cursed-child-london-just-released': '/news',
  '/news/cursed-child-nabs-a-record-11-olivier-award-nominations': '/news',
  '/news/harry-potter-and-the-cursed-child-tickets-on-sale-now': '/news',
  '/news/hair-and-makeup-on-set-of-fantastic-beasts': '/news',
  '/news/eddie-redmayne-message-to-fans-at-a-celebration-of-harry-potter-2017': '/news',
  '/news/artists-jim-kay-and-olivia-lomenech-gill-discuss-their-animated-digital-books': '/news',
  '/news/win-fantastic-prizes-with-the-wizarding-wednesdays-weekly-giveaway': '/news',
  '/news/first-look-at-eddie-redmayne-as-newt-scamander': '/news',
  '/news/new-lumos-campaign-offers-grand-prize-to-see-cursed-child-in-new-york': '/news',
  '/news/which-is-eddie-redmaynes-favourite-fantastic-beast': '/news',
  '/news/singapores-changi-airport-has-been-given-a-festive-wizarding-world-makeover': '/news',
  '/news/graphic-art-of-harry-potter-films-exhibition-opens': '/news',
  '/news/remembering-robert-hardy': '/news',
  '/news/harry-potter-a-history-of-magic-opens-today-at-the-british-library': '/news',
  '/news/a-very-fantastic-flight-to-new-york': '/news',
  '/news/introducing-newt-scamander-fantastic-beasts': '/news',
  '/news/gifting-on-pottermore-shop': '/news',
  '/news/fantastic-beasts-interview-series-ezra-miller': '/news',
  '/news/cursed-child-actor-anthony-boyle-interview': '/news',
  '/news/deluxe-illustrated-philosophers-stone-signed-by-jk-rowling-goes-to-auction': '/news',
  '/news/fantastic-beasts-interview-series-stuart-craig': '/news',
  '/news/discover-the-dark-arts-at-warner-bros-studio-tour': '/news',
  '/news/best-fan-reactions-to-harry-potter-and-the-cursed-child-script-book': '/news',
  '/news/looking-after-hogwarts-at-warner-bros-studio-tour-london': '/news',
  '/news/new-gameplay-footage-revealed-from-harry-potter-hogwarts-mystery-mobile-game': '/news',
  '/news/hagrid-half-giant-warner-bros-studio-tour': '/news',
  '/news/remastered-harry-potter-lego-games-for-xboxone-and-nintendo-switch-out-today': '/news',
  '/news/pmc-guide-to-movie-making-shadowing-director-david-yates': '/news',
  '/news/pottermore-art-collection-now-shipping-to-europe': '/news',
  '/news/james-oliver-phelps-harry-potter-exhibition-brussels': '/news',
  '/news/jude-law-and-eddie-redmayne-surprise-fans-at-kings-cross': '/news',
  '/news/pottermore-correspondent-meets-fans-at-september-screenings': '/news',
  '/news/fantastic-beasts-alison-sudol-interview-playing-queenie': '/news',
  '/news/hundreds-of-tickets-released-for-cursed-child-london-1-september-performances': '/news',
  '/news/pottermore-to-host-pop-up-shop-for-new-art-collection': '/news',
  '/news/cursed-child-steven-hoggett-interview': '/news',
  '/news/behind-the-scenes-video-house-of-minalima': '/news',
  '/news/curtain-comes-down-on-cursed-child-opening-performance': '/news',
  '/news/jim-kay-on-designing-voldemort-for-illustrated-edition': '/news',
  '/news/harry-potter-and-the-cursed-child-opens-on-broadway': '/news',
  '/news/stepping-inside-privet-drive-warner-bros-studio-tour': '/news',
  '/news/learn-to-code-with-kano-new-coding-kit-inspired-by-harry-potter': '/news',
  '/news/video-cursed-child-character-photo-shoot': '/news',
  '/news/reserve-tickets-for-harry-potter-a-history-of-magic-in-new-york': '/news',
  '/news/new-range-of-harry-potter-concept-design-prints-released-by-minalima': '/news',
  '/news/stunt-coordinator-eunice-huthart-on-eddie-redmaynes-daredevil-streak': '/news',
  '/news/jk-rowling-and-cast-of-fantastic-beasts-the-crimes-of-grindelwald-delight-fans-in-new-york':
    '/news',
  '/news/miraphora-mina-discusses-limited-edition-harry-potter-and-fantastic-beasts-prints':
    '/news',
  '/news/fantastic-beasts-interview-series-minalima': '/news',
  '/news/one-week-to-go-before-the-opening-of-harry-potter-a-history-of-magic': '/news',
  '/news/giant-lego-nifflers-have-been-let-loose-around-the-uk': '/news',
  '/news/all-the-action-from-the-red-carpet-of-the-fantastic-beasts-the-crimes-of-grindelwald-world-premiere':
    '/news',
  '/news/fans-embrace-the-dark-arts-at-warner-bros-studio-tour': '/news',
  '/news/first-look-olly-moss-redesigns-fantastic-beasts-for-pottermore': '/news',
  '/news/fantastic-beasts-the-crimes-of-grindelwald-makers-mysteries-magic-out-today': '/news',
  '/news/what-magical-pets-would-the-harry-potter-cast-have-in-the-wizarding-world': '/news',
  '/news/natalia-tena-to-join-special-guests-at-a-celebration-of-harry-potter-2018': '/news',
  '/news/fantastic-beasts-inside-new-york-tenement-museum': '/news',
  '/news/when-jk-rowling-met-the-cursed-child-cast': '/news',
  '/news/fantastic-beasts-cast-interview-on-characters-no-majs-and-wand-envy': '/news',
  '/news/the-tales-of-beedle-the-bard-illustrated-by-chris-riddell-goes-on-sale-in-the-uk-today':
    '/news',
  '/news/harry-potter-charm-bracelet-collection-to-raise-money-for-lumos': '/news',
  '/news/what-new-york-city-means-to-the-fantastic-beasts-cast': '/news',
  '/news/comic-con-exclusive-newt-scamander-funko': '/news',
  '/news/the-magic-of-christmas-at-warner-bros-studio-tour-london': '/news',
  '/news/cursed-child-sam-clemmett-interview': '/news',
  '/news/tickets-go-on-sale-for-harry-potter-a-history-of-magic-at-the-new-york-historical-society':
    '/news',
  '/news/cursed-child-actor-paul-thornley-interview': '/news',
  '/news/new-talent-announced-for-a-celebration-of-harry-potter': '/news',
  '/news/pmc-guide-to-movie-making-fantastic-beasts-film-trailer': '/news',
  '/news/andrew-lincoln-discovers-his-hogwarts-house-and-patronus': '/news',
  '/news/2016-in-review-the-year-we-returned-to-the-wizarding-world': '/news',
  '/news/fantastic-beasts-dan-fogler-alison-sudol-interview': '/news',
  '/news/watch-fantastic-beasts-actor-eddie-redmayne-discover-his-patronus': '/news',
  '/news/surprises-in-store-at-celebration-of-harry-potter-event': '/news',
  '/news/video-bonnie-wright-sorting-interview': '/news',
  '/news/cursed-child-to-put-on-autism-friendly-performance-in-london': '/news',
  '/news/harry-potter-a-history-of-magic-opens-at-the-new-york-historical-society': '/news',
  '/news/new-images-from-the-fantastic-beasts-illustrated-edition-library-book': '/news',
  '/news/special-guests-announced-for-a-celebration-of-harry-potter-2018': '/news',
  '/news/the-best-harry-potter-outfits-at-a-celebration-of-harry-potter-2018': '/news',
  '/news/warner-bros-studio-tour-welcomes-you-back-to-hogwarts': '/news',
  '/news/harry-potter-book-night-2016': '/news',
  '/news/eddie-redmayne-surprises-harry-potter-fans-with-visit-to-studio-tour-hogwarts': '/news',
  '/news/cursed-childs-jack-thorne-and-john-tiffany-discuss-script-reading-and-writing': '/news',
  '/news/fantastic-beasts-graphic-art-goes-on-display-at-house-of-minalima': '/news',
  '/news/first-look-inside-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/news',
  '/news/cursed-child-gala-performance-for-lumos': '/news',
  '/news/explore-the-british-librarys-harry-potter-a-history-of-magic-exhibition-with-google-arts-and-culture':
    '/news',
  '/news/harry-potter-and-the-cursed-child-heads-to-australia': '/news',
  '/news/remembering-alan-rickman': '/news',
  '/news/warwick-davis-interviews-lumos-ceo': '/news',
  '/news/fantastic-beasts-ezra-miller-needs-to-be-sorted-into-gryffindor': '/news',
  '/news/interview-with-fantastic-beasts-composer-james-newton-howard': '/news',
  '/news/harry-potter-and-the-cursed-child-to-open-in-san-francisco': '/news',
  '/news/watch-the-fantastic-beasts-cast-discover-their-patronuses': '/news',
  '/news/pmc-guide-to-movie-making-creating-new-york-skyline': '/news',
  '/news/lumos-offers-fans-chance-to-spend-a-day-with-fantastic-beasts-cast': '/news',
  '/news/video-evanna-lynch-sorting-interview': '/news',
  '/news/hogwarts-pops-into-life-with-new-guide-to-the-school': '/news',
  '/news/new-limited-edition-signed-prints-now-available-from-the-pottermore-art-collection':
    '/news',
  '/news/fan-reactions-from-the-midnight-screening-of-fantastic-beasts': '/news',
  '/news/first-phase-cursed-child-front-of-house-unveiled': '/news',
  '/news/step-inside-fantastic-beasts-1920s-new-york-speakeasy-blind-pig': '/news',
  '/news/katherine-waterston-alison-sudol-interview-fantastic-beasts': '/news',
  '/news/jk-rowling-and-the-fantastic-beasts-cast-delight-at-global-fan-event': '/news',
  '/news/lumos-launches-a-christmas-campaign-for-haiti-with-fantastic-beasts-themed-prizes':
    '/news',
  '/news/interview-with-the-producers-of-the-new-bbc-documentary-harry-potter-a-history-of-magic':
    '/news',
  '/news/when-pottermore-was-welcomed-back-to-hogwarts': '/news',
  '/news/fantastic-beasts-interview-series-katherine-waterston': '/news',
  '/news/fantastic-beasts-how-katherine-waterston-reacts-to-a-compliment': '/news',
  '/news/fantastic-beasts-stuart-craig-interview-macusa-salem-memorial': '/news',
  '/news/pottermore-introduces-new-stationery-range': '/news',
  '/news/watch-jk-rowling-john-tiffany-jack-thorne-reflect-on-cursed-child-as-the-play-gets-ready-for-australia':
    '/news',
  '/news/rehearsals-begin-for-harry-potter-and-the-cursed-child-on-broadway': '/news',
  '/news/cursed-child-jamie-parker-interview': '/news',
  '/news/pmc-guide-to-movie-making-fantastic-beasts-brie': '/news',
  '/news/why-carmen-ejogo-loves-macusa-president-seraphinas-wand': '/news',
  '/news/the-music-of-harry-potter-and-the-cursed-child-is-out-today': '/news',
  '/news/broadway-lyric-theatre-unveils-cursed-child-frontage': '/news',
  '/news/colin-farrell-walks-into-a-room': '/news',
  '/news/fantastic-beasts-interview-series-dan-fogler': '/news',
  '/news/one-week-on-cursed-child-previews-begin-on-broadway': '/news',
  '/news/longbottom-family-reunion-on-fantastic-beasts-set': '/news',
  '/news/interview-with-the-artist-behind-the-fantastic-beasts-and-where-to-find-them-illustrated-edition':
    '/news',
  '/news/illustrated-kindle-in-motion-edition-of-fantastic-beasts-is-out-today': '/news',
  '/features/behind-the-scenes-the-shrieking-shack': '/features',
  '/features/behind-the-scenes-the-basilisk': '/features',
  '/features/a-closer-look-at-the-characters-of-fantastic-beasts-the-crimes-of-grindelwald':
    '/features',
  '/features/behind-the-scenes-at-the-battle-of-hogwarts': '/features',
  '/features/behind-the-scenes-mary-lou-barebone': '/features',
  '/features/sayre-family-tree': '/features',
  '/features/gaunt-family-tree': '/features',
  '/features/black-family-tree': '/features',
  '/features/behind-the-scenes-werewolves': '/features',
  '/features/behind-the-scenes-trolls': '/features',
  '/features/behind-the-scenes-grimmauld-place': '/features',
  '/features/behind-the-scenes-lucius-and-narcissa-malfoy': '/features',
  '/features/behind-the-scenes-inferi': '/features',
  '/features/behind-the-scenes-fantastic-beasts-nifflers': '/features',
  '/features/behind-the-scenes-platform-nine-and-three-quarters': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-credence': '/features',
  '/features/behind-the-scenes-the-forbidden-forest': '/features',
  '/features/wizarding-world-book-o-graphic': '/features',
  '/features/behind-the-scenes-argus-filch': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-inside-newts-shed': '/features',
  '/features/behind-the-scenes-professor-quirrell': '/features',
  '/features/the-weasley-family-tree': '/features',
  '/features/behind-the-scenes-cornish-pixies': '/features',
  '/features/the-definitive-guide-to-the-order-of-the-phoenix-and-dumbledores-army-infographic':
    '/features',
  '/features/behind-the-scenes-budleigh-babberton': '/features',
  '/features/behind-the-scenes-malfoy-manor': '/features',
  '/features/behind-the-scenes-madam-pomfrey': '/features',
  '/features/behind-the-scenes-giants': '/features',
  '/features/behind-the-scenes-designing-fawkes': '/features',
  '/features/behind-the-scenes-professor-sprout': '/features',
  '/features/a-guide-to-lord-voldemorts-death-eaters': '/features',
  '/features/care-of-magical-creatures-illustrations-winged-horses': '/features',
  '/features/behind-the-scenes-hogsmeade': '/features',
  '/features/wonderful-wizarding-world-happiness-generator': '/features',
  '/features/behind-the-scenes-professor-trelawney': '/features',
  '/features/behind-the-scenes-designing-diagon-alley-from-scratch': '/features',
  '/features/behind-the-scenes-the-leaky-cauldron': '/features',
  '/features/behind-the-scenes-mandrakes': '/features',
  '/features/behind-the-scenes-moving-staircases': '/features',
  '/features/behind-the-scenes-grindylows': '/features',
  '/features/behind-the-scenes-defence-against-the-dark-arts-classroom': '/features',
  '/features/behind-the-scenes-whomping-willow': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-newt-scamander': '/features',
  '/features/the-life-and-times-of-albus-dumbledore': '/features',
  '/features/behind-the-scenes-nymphadora-tonks': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-the-diamond-district': '/features',
  '/features/behind-the-scenes-kingsley-shacklebolt': '/features',
  '/features/behind-the-scenes-thestrals': '/features',
  '/features/behind-the-scenes-peter-pettigrew': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-wanted-posters': '/features',
  '/features/behind-the-scenes-professor-flitwick': '/features',
  '/features/behind-the-scenes-fred-and-george-weasley': '/features',
  '/features/behind-the-scenes-designing-the-props-for-fantastic-beasts-and-where-to-find-them':
    '/features',
  '/features/the-malfoy-family-tree': '/features',
  '/features/behind-the-scenes-deathly-hallows': '/features',
  '/features/behind-the-scenes-centaurs': '/features',
  '/features/behind-the-scenes-acromantula': '/features',
  '/features/behind-the-scenes-lovegood-house': '/features',
  '/features/behind-the-scenes-horace-slughorn': '/features',
  '/features/behind-the-scenes-boggarts': '/features',
  '/features/behind-the-scenes-mimbulus-mimbletonia': '/features',
  '/features/behind-the-scenes-knight-bus': '/features',
  '/features/behind-the-scenes-animagus': '/features',
  '/features/behind-the-scenes-professor-lockhart': '/features',
  '/features/the-potter-family-tree': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-the-wands-of-the-characters': '/features',
  '/features/behind-the-scenes-the-burrow': '/features',
  '/features/behind-the-scenes-spinners-end': '/features',
  '/features/behind-the-scenes-xenophilius-lovegood': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-bowtruckles': '/features',
  '/features/behind-the-scenes-cornelius-fudge': '/features',
  '/features/christmas-corner-2017': '/features',
  '/features/behind-the-scenes-fantastic-beast-newts-wand': '/features',
  '/features/room-of-requirement-infographic': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-thunderbirds': '/features',
  '/features/behind-the-scenes-marge-dursley': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-queenie-goldstein': '/features',
  '/features/behind-the-scenes-the-ghosts-of-hogwarts': '/features',
  '/features/the-longbottom-family-tree': '/features',
  '/features/behind-the-scenes-fantastic-beasts-the-blind-pig': '/features',
  '/features/behind-the-scenes-at-the-yule-ball': '/features',
  '/features/behind-the-scenes-little-hangleton-graveyard': '/features',
  '/features/behind-the-scenes-designing-giant-wizard-chess': '/features',
  '/features/gellert-grindelwald-the-story-so-far': '/features',
  '/features/behind-the-scenes-rita-skeeter': '/features',
  '/features/behind-the-scenes-james-and-lily-potter': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-gnarlak': '/features',
  '/features/behind-the-scenes-gringotts': '/features',
  '/features/behind-the-scenes-hippogriffs': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-and-where-find-them-times-square-in-the-1920s':
    '/features',
  '/features/behind-the-scenes-the-horcruxes': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-macusa': '/features',
  '/features/behind-the-scenes-dementors': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-tina-goldstein': '/features',
  '/features/behind-the-scenes-honeydukes': '/features',
  '/features/behind-the-scenes-the-hogs-head': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-jacob-kowalski': '/features',
  '/features/behind-the-scenes-of-fantastic-beasts-modesty-and-chastity-barebone': '/features',
  '/features/behind-the-scenes-post-owls': '/features',
  '/features/behind-the-scenes-madam-hooch': '/features',
  '/features/fantastic-beasts-dan-fogler-eddie-redmayne-interview': '/features',
  '/features/the-emotional-last-days-of-filming-fantastic-beasts': '/features',
  '/features/fantastic-beasts-interview-series-eddie-redmayne': '/features',
  '/features/the-british-library-reveals-details-on-harry-potter-a-history-of-magic-exhibition':
    '/features',
  '/features/fantastic-beasts-the-crimes-of-grindelwald-to-have-its-world-premiere-in-paris':
    '/features',
  '/features/san-diego-comic-con-2016-with-cast-of-fantastic-beasts': '/features',
  '/features/new-items-revealed-from-british-library-harry-potter-exhibition-including-jk-rowling-sorting-hat-song-draft':
    '/features',
  '/features/jack-thorne-reacts-to-harry-potter-and-the-cursed-childs-best-play-win': '/features',
  '/features/cursed-child-to-open-in-germany-in-2020': '/features',
  '/features/pottermore-sorcerers-stone-live-at-hollywood-bowl': '/features',
  '/features/pottermore-meets-stars-at-wizarding-world-hollywood': '/features',
  '/features/british-library-curators-pick-their-favourite-rooms-in-harry-potter-a-history-of-magic':
    '/features',
  '/features/fantastic-beasts-inside-goldstein-sisters-apartment': '/features',
  '/features/remembering-john-hurt': '/features',
  '/features/fans-tweet-their-favourite-memories-at-celebration-of-harry-potter': '/features',
  '/features/harry-potter-comes-to-lego-dimensions': '/features',
  '/features/fantastic-beasts-interview-series-carmen-ejogo': '/features',
  '/features/harry-potter-aragog-creature-designer-nick-dudman-bad-with-spiders': '/features',
  '/features/jim-kay-speaks-at-house-of-illustration': '/features',
  '/features/cursed-child-wins-eight-whatsonstage-awards': '/features',
  '/features/behind-the-scenes-with-cursed-child-costume-designer-katrina-lindsay': '/features',
  '/features/jk-rowling-and-bonnie-wright-challenge-us-to-spread-the-word-about-lumos': '/features',
  '/features/explore-the-darkness-of-the-wizarding-world-this-halloween-at-warner-bros-studio-tour-london':
    '/features',
  '/features/harry-potter-and-the-cursed-child-wins-record-breaking-nine-2017-olivier-awards':
    '/features',
  '/features/exclusive-interview-fantastic-beasts-cast-san-diego-comic-con-2016': '/features',
  '/features/fans-dress-up-for-halloween-at-warner-bros-studio-tour-london': '/features',
  '/features/first-look-at-the-australian-cursed-child-production-full-company': '/features',
  '/features/anything-is-possible-say-cursed-child-producers': '/features',
  '/features/would-you-like-to-dine-in-the-great-hall': '/features',
  '/features/fantastic-beasts-interview-series-alison-sudol': '/features',
  '/features/tickets-for-harry-potter-a-history-of-magic-in-new-york-coming-soon': '/features',
  '/features/win-tickets-for-lumos-fantastic-beasts-advance-screening-in-new-york': '/features',
  '/features/video-rupert-grint-sorting-interview': '/features',
  '/features/behind-the-scenes-of-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/features',
  '/features/8-exciting-clues-from-the-fantastic-beasts-featurette': '/features',
  '/features/pmc-celebrating-the-journey-to-the-eighth-harry-potter-story': '/features',
  '/features/the-best-fan-photos-from-celebration-of-harry-potter-2017': '/features',
  '/features/exclusive-video-fantastic-beasts-cast-on-working-with-the-harry-potter-creative-team':
    '/features',
  '/features/over-10000-tickets-to-be-released-for-cursed-child-on-broadway': '/features',
  '/features/fantastic-beasts-interview-series-david-yates': '/features',
  '/features/wand-attractions-open-at-wizarding-world-harry-potter-japan': '/features',
  '/features/video-matthew-lewis-sorting-interview': '/features',
  '/features/cursed-child-london-cast-visit-the-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/features',
  '/features/jk-rowling-attends-fantastic-beasts-world-premiere-in-new-york': '/features',
  '/features/all-the-wizarding-world-action-from-san-diego-comic-con-2019': '/features',
  '/features/harry-potter-a-history-of-magic-us-companion-book-cover-revealed': '/features',
  '/features/harry-potter-film-concert-series-uk-tour-interview-with-justin-freer': '/features',
  '/features/why-fantastic-beasts-graphic-artists-minalima-love-twenties-design': '/features',
  '/features/warner-bros-studio-tour-breakfast-at-hogwarts': '/features',
  '/features/the-day-pottermore-stepped-inside-newt-scamanders-magical-case': '/features',
  '/features/happy-anniversary-cursed-child-everything-you-need-to-know-about-the-latest-productions':
    '/features',
  '/features/british-library-exhibition-harry-potter-a-history-of-magic-to-open-in-new-york-next-year':
    '/features',
  '/features/cover-revealed-for-harry-potter-and-the-cursed-child-the-journey': '/features',
  '/features/jason-isaacs-on-bully-lucius-malfoy': '/features',
  '/features/im-a-gryffindor-but-i-kinda-wanted-to-be-a-slytherin-interview-with-imogen-heap':
    '/features',
  '/features/wizarding-wardrobes-opens-at-warner-bros-studio-tour-london': '/features',
  '/features/100-days-until-fantastic-beasts-and-where-to-find-them-hits-cinemas': '/features',
  '/features/from-the-red-carpet-harry-potter-and-the-cursed-child': '/features',
  '/features/fantastic-beasts-the-crimes-of-grindelwald-is-out-today': '/features',
  '/features/report-from-waterstones-piccadilly-cursed-child-book-launch': '/features',
  '/features/harry-potter-the-exhibition-now-open-in-germany': '/features',
  '/features/crimes-of-grindelwald-cast-surprise-fans-at-alabama-school': '/features',
  '/features/how-to-register-for-tickets-to-see-harry-potter-and-the-cursed-child-on-broadway':
    '/features',
  '/features/house-of-minalima-has-a-new-fantastic-beasts-the-crimes-of-grindelwald-exhibition':
    '/features',
  '/features/first-look-at-new-west-end-cast-for-harry-potter-and-the-cursed-child': '/features',
  '/features/minalima-interview-pottermore-presents-covers': '/features',
  '/features/what-wizarding-world-jobs-would-the-harry-potter-cast-members-have': '/features',
  '/features/fantastic-beasts-dog-teddy-redmayne': '/features',
  '/features/new-quidditch-through-the-ages-audiobook-launches-in-london': '/features',
  '/features/watch-the-final-fantastic-beasts-trailer': '/features',
  '/features/fantastic-beasts-movie-films-in-liverpool': '/features',
  '/features/harry-potter-and-the-prisoner-of-azkaban-illustrated-edition-goes-on-sale-today':
    '/features',
  '/features/how-is-hogwarts-league-faring-ahead-of-sport-relief-week': '/features',
  '/features/jk-rowling-takes-us-inside-cursed-child-rehearsals': '/features',
  '/features/new-sport-relief-app-challenges-harry-potter-fans-to-join-billion-steps-challenge':
    '/features',
  '/features/exclusive-look-at-the-harry-potter-colouring-book-out-today': '/features',
  '/features/harry-potter-fantastic-beasts-coming-to-lego-dimensions': '/features',
  '/features/all-the-action-from-the-fantastic-beasts-the-crimes-of-grindelwald-comic-con-panel':
    '/features',
  '/features/eddie-redmayne-on-recording-newt-scamanders-life-work': '/features',
  '/features/warner-bros-studio-tour-london-unveils-new-behind-the-seams-experience': '/features',
  '/features/fantastic-beasts-sneak-peek-at-celebration-of-harry-potter': '/features',
  '/features/make-christmas-extra-festive-this-year-with-these-wizarding-world-albums': '/features',
  '/features/j-k-rowling-welcome-message': '/features',
  '/features/watch-andrew-lincoln-discuss-his-quidditch-through-the-ages-audiobook': '/features',
  '/features/fantastic-beasts-carmen-ejogo-seraphina-interview': '/features',
  '/features/harry-potter-fans-gather-at-kings-cross-to-celebrate-going-back-to-hogwarts':
    '/features',
  '/features/a-day-in-the-life-of-a-fantastic-beasts-extra': '/features',
  '/features/jk-rowling-to-launch-new-lumos-campaign-with-live-interview': '/features',
  '/features/lumos-new-york-screening-fantastic-beasts': '/features',
  '/features/pmc-was-cast-as-a-no-maj-extra-in-fantastic-beasts': '/features',
  '/features/history-of-magic-in-north-america-starts-today-on-pottermore': '/features',
  '/features/fans-flock-to-kings-cross-to-celebrate-20-years-of-harry-potter-and-the-philosophers-stone':
    '/features',
  '/features/christmas-comes-to-warner-bros-studio-tour-london': '/features',
  '/features/bonnie-wright-is-the-latest-guest-added-to-a-celebration-of-harry-potter-2018':
    '/features',
  '/features/goblet-of-fire-coming-to-warner-bros-studio-tour-london': '/features',
  '/features/fantastic-beasts-eddie-redmayne-interview-mtv-movie-awards': '/features',
  '/features/designer-reveals-how-she-colour-coded-the-new-pottermore': '/features',
  '/features/win-tickets-for-fantastic-beasts-crimes-of-grindelwald-premiere-with-lumos':
    '/features',
  '/features/all-the-action-from-new-york-comic-con-2018': '/features',
  '/features/warner-bros-studio-tour-london-announce-new-forbidden-forest-attraction': '/features',
  '/features/cursed-child-weekly-ticket-release': '/features',
  '/features/san-diego-comic-con-2016-harry-potter-cosplayers': '/features',
  '/features/jk-rowling-and-fantastic-beasts-cast-at-european-premiere': '/features',
  '/features/more-tickets-for-cursed-child-london-just-released': '/features',
  '/features/cursed-child-nabs-a-record-11-olivier-award-nominations': '/features',
  '/features/harry-potter-and-the-cursed-child-tickets-on-sale-now': '/features',
  '/features/hair-and-makeup-on-set-of-fantastic-beasts': '/features',
  '/features/eddie-redmayne-message-to-fans-at-a-celebration-of-harry-potter-2017': '/features',
  '/features/artists-jim-kay-and-olivia-lomenech-gill-discuss-their-animated-digital-books':
    '/features',
  '/features/win-fantastic-prizes-with-the-wizarding-wednesdays-weekly-giveaway': '/features',
  '/features/first-look-at-eddie-redmayne-as-newt-scamander': '/features',
  '/features/new-lumos-campaign-offers-grand-prize-to-see-cursed-child-in-new-york': '/features',
  '/features/which-is-eddie-redmaynes-favourite-fantastic-beast': '/features',
  '/features/singapores-changi-airport-has-been-given-a-festive-wizarding-world-makeover':
    '/features',
  '/features/graphic-art-of-harry-potter-films-exhibition-opens': '/features',
  '/features/remembering-robert-hardy': '/features',
  '/features/harry-potter-a-history-of-magic-opens-today-at-the-british-library': '/features',
  '/features/a-very-fantastic-flight-to-new-york': '/features',
  '/features/introducing-newt-scamander-fantastic-beasts': '/features',
  '/features/gifting-on-pottermore-shop': '/features',
  '/features/fantastic-beasts-interview-series-ezra-miller': '/features',
  '/features/cursed-child-actor-anthony-boyle-interview': '/features',
  '/features/deluxe-illustrated-philosophers-stone-signed-by-jk-rowling-goes-to-auction':
    '/features',
  '/features/fantastic-beasts-interview-series-stuart-craig': '/features',
  '/features/discover-the-dark-arts-at-warner-bros-studio-tour': '/features',
  '/features/best-fan-reactions-to-harry-potter-and-the-cursed-child-script-book': '/features',
  '/features/looking-after-hogwarts-at-warner-bros-studio-tour-london': '/features',
  '/features/new-gameplay-footage-revealed-from-harry-potter-hogwarts-mystery-mobile-game':
    '/features',
  '/features/hagrid-half-giant-warner-bros-studio-tour': '/features',
  '/features/remastered-harry-potter-lego-games-for-xboxone-and-nintendo-switch-out-today':
    '/features',
  '/features/pmc-guide-to-movie-making-shadowing-director-david-yates': '/features',
  '/features/pottermore-art-collection-now-shipping-to-europe': '/features',
  '/features/james-oliver-phelps-harry-potter-exhibition-brussels': '/features',
  '/features/jude-law-and-eddie-redmayne-surprise-fans-at-kings-cross': '/features',
  '/features/pottermore-correspondent-meets-fans-at-september-screenings': '/features',
  '/features/fantastic-beasts-alison-sudol-interview-playing-queenie': '/features',
  '/features/hundreds-of-tickets-released-for-cursed-child-london-1-september-performances':
    '/features',
  '/features/pottermore-to-host-pop-up-shop-for-new-art-collection': '/features',
  '/features/cursed-child-steven-hoggett-interview': '/features',
  '/features/behind-the-scenes-video-house-of-minalima': '/features',
  '/features/curtain-comes-down-on-cursed-child-opening-performance': '/features',
  '/features/jim-kay-on-designing-voldemort-for-illustrated-edition': '/features',
  '/features/harry-potter-and-the-cursed-child-opens-on-broadway': '/features',
  '/features/stepping-inside-privet-drive-warner-bros-studio-tour': '/features',
  '/features/learn-to-code-with-kano-new-coding-kit-inspired-by-harry-potter': '/features',
  '/features/video-cursed-child-character-photo-shoot': '/features',
  '/features/reserve-tickets-for-harry-potter-a-history-of-magic-in-new-york': '/features',
  '/features/new-range-of-harry-potter-concept-design-prints-released-by-minalima': '/features',
  '/features/stunt-coordinator-eunice-huthart-on-eddie-redmaynes-daredevil-streak': '/features',
  '/features/jk-rowling-and-cast-of-fantastic-beasts-the-crimes-of-grindelwald-delight-fans-in-new-york':
    '/features',
  '/features/miraphora-mina-discusses-limited-edition-harry-potter-and-fantastic-beasts-prints':
    '/features',
  '/features/fantastic-beasts-interview-series-minalima': '/features',
  '/features/one-week-to-go-before-the-opening-of-harry-potter-a-history-of-magic': '/features',
  '/features/giant-lego-nifflers-have-been-let-loose-around-the-uk': '/features',
  '/features/all-the-action-from-the-red-carpet-of-the-fantastic-beasts-the-crimes-of-grindelwald-world-premiere':
    '/features',
  '/features/fans-embrace-the-dark-arts-at-warner-bros-studio-tour': '/features',
  '/features/first-look-olly-moss-redesigns-fantastic-beasts-for-pottermore': '/features',
  '/features/fantastic-beasts-the-crimes-of-grindelwald-makers-mysteries-magic-out-today':
    '/features',
  '/features/what-magical-pets-would-the-harry-potter-cast-have-in-the-wizarding-world':
    '/features',
  '/features/natalia-tena-to-join-special-guests-at-a-celebration-of-harry-potter-2018':
    '/features',
  '/features/fantastic-beasts-inside-new-york-tenement-museum': '/features',
  '/features/when-jk-rowling-met-the-cursed-child-cast': '/features',
  '/features/fantastic-beasts-cast-interview-on-characters-no-majs-and-wand-envy': '/features',
  '/features/the-tales-of-beedle-the-bard-illustrated-by-chris-riddell-goes-on-sale-in-the-uk-today':
    '/features',
  '/features/harry-potter-charm-bracelet-collection-to-raise-money-for-lumos': '/features',
  '/features/what-new-york-city-means-to-the-fantastic-beasts-cast': '/features',
  '/features/comic-con-exclusive-newt-scamander-funko': '/features',
  '/features/the-magic-of-christmas-at-warner-bros-studio-tour-london': '/features',
  '/features/cursed-child-sam-clemmett-interview': '/features',
  '/features/tickets-go-on-sale-for-harry-potter-a-history-of-magic-at-the-new-york-historical-society':
    '/features',
  '/features/cursed-child-actor-paul-thornley-interview': '/features',
  '/features/new-talent-announced-for-a-celebration-of-harry-potter': '/features',
  '/features/pmc-guide-to-movie-making-fantastic-beasts-film-trailer': '/features',
  '/features/andrew-lincoln-discovers-his-hogwarts-house-and-patronus': '/features',
  '/features/2016-in-review-the-year-we-returned-to-the-wizarding-world': '/features',
  '/features/fantastic-beasts-dan-fogler-alison-sudol-interview': '/features',
  '/features/watch-fantastic-beasts-actor-eddie-redmayne-discover-his-patronus': '/features',
  '/features/surprises-in-store-at-celebration-of-harry-potter-event': '/features',
  '/features/video-bonnie-wright-sorting-interview': '/features',
  '/features/cursed-child-to-put-on-autism-friendly-performance-in-london': '/features',
  '/features/harry-potter-a-history-of-magic-opens-at-the-new-york-historical-society': '/features',
  '/features/new-images-from-the-fantastic-beasts-illustrated-edition-library-book': '/features',
  '/features/special-guests-announced-for-a-celebration-of-harry-potter-2018': '/features',
  '/features/the-best-harry-potter-outfits-at-a-celebration-of-harry-potter-2018': '/features',
  '/features/warner-bros-studio-tour-welcomes-you-back-to-hogwarts': '/features',
  '/features/harry-potter-book-night-2016': '/features',
  '/features/eddie-redmayne-surprises-harry-potter-fans-with-visit-to-studio-tour-hogwarts':
    '/features',
  '/features/cursed-childs-jack-thorne-and-john-tiffany-discuss-script-reading-and-writing':
    '/features',
  '/features/fantastic-beasts-graphic-art-goes-on-display-at-house-of-minalima': '/features',
  '/features/first-look-inside-harry-potter-a-history-of-magic-exhibition-at-the-british-library':
    '/features',
  '/features/cursed-child-gala-performance-for-lumos': '/features',
  '/features/explore-the-british-librarys-harry-potter-a-history-of-magic-exhibition-with-google-arts-and-culture':
    '/features',
  '/features/harry-potter-and-the-cursed-child-heads-to-australia': '/features',
  '/features/remembering-alan-rickman': '/features',
  '/features/warwick-davis-interviews-lumos-ceo': '/features',
  '/features/fantastic-beasts-ezra-miller-needs-to-be-sorted-into-gryffindor': '/features',
  '/features/interview-with-fantastic-beasts-composer-james-newton-howard': '/features',
  '/features/harry-potter-and-the-cursed-child-to-open-in-san-francisco': '/features',
  '/features/watch-the-fantastic-beasts-cast-discover-their-patronuses': '/features',
  '/features/pmc-guide-to-movie-making-creating-new-york-skyline': '/features',
  '/features/lumos-offers-fans-chance-to-spend-a-day-with-fantastic-beasts-cast': '/features',
  '/features/video-evanna-lynch-sorting-interview': '/features',
  '/features/hogwarts-pops-into-life-with-new-guide-to-the-school': '/features',
  '/features/new-limited-edition-signed-prints-now-available-from-the-pottermore-art-collection':
    '/features',
  '/features/fan-reactions-from-the-midnight-screening-of-fantastic-beasts': '/features',
  '/features/first-phase-cursed-child-front-of-house-unveiled': '/features',
  '/features/step-inside-fantastic-beasts-1920s-new-york-speakeasy-blind-pig': '/features',
  '/features/katherine-waterston-alison-sudol-interview-fantastic-beasts': '/features',
  '/features/jk-rowling-and-the-fantastic-beasts-cast-delight-at-global-fan-event': '/features',
  '/features/lumos-launches-a-christmas-campaign-for-haiti-with-fantastic-beasts-themed-prizes':
    '/features',
  '/features/interview-with-the-producers-of-the-new-bbc-documentary-harry-potter-a-history-of-magic':
    '/features',
  '/features/when-pottermore-was-welcomed-back-to-hogwarts': '/features',
  '/features/fantastic-beasts-interview-series-katherine-waterston': '/features',
  '/features/fantastic-beasts-how-katherine-waterston-reacts-to-a-compliment': '/features',
  '/features/fantastic-beasts-stuart-craig-interview-macusa-salem-memorial': '/features',
  '/features/pottermore-introduces-new-stationery-range': '/features',
  '/features/watch-jk-rowling-john-tiffany-jack-thorne-reflect-on-cursed-child-as-the-play-gets-ready-for-australia':
    '/features',
  '/features/rehearsals-begin-for-harry-potter-and-the-cursed-child-on-broadway': '/features',
  '/features/cursed-child-jamie-parker-interview': '/features',
  '/features/pmc-guide-to-movie-making-fantastic-beasts-brie': '/features',
  '/features/why-carmen-ejogo-loves-macusa-president-seraphinas-wand': '/features',
  '/features/the-music-of-harry-potter-and-the-cursed-child-is-out-today': '/features',
  '/features/broadway-lyric-theatre-unveils-cursed-child-frontage': '/features',
  '/features/colin-farrell-walks-into-a-room': '/features',
  '/features/fantastic-beasts-interview-series-dan-fogler': '/features',
  '/features/one-week-on-cursed-child-previews-begin-on-broadway': '/features',
  '/features/longbottom-family-reunion-on-fantastic-beasts-set': '/features',
  '/features/interview-with-the-artist-behind-the-fantastic-beasts-and-where-to-find-them-illustrated-edition':
    '/features',
  '/features/illustrated-kindle-in-motion-edition-of-fantastic-beasts-is-out-today': '/features',
  '/book-extract-long/harrys-school-list': `/writing-by-jk-rowling/wizarding-schools`,
  '/book-extract-long/entering-the-wizarding-world': `/writing-by-jk-rowling/wizarding-schools`,
  '/book-extract-long/twin-cores-collide': `/writing-by-jk-rowling/wand-cores`,
  '/book-extract-long/petunias-howler': `/writing-by-jk-rowling/vernon-and-petunia-dursley`,
  '/book-extract-long/hermione-uses-the-time-turner': `/writing-by-jk-rowling/time-turner`,
  '/book-extract-long/first-time-in-knockturn-alley': `/writing-by-jk-rowling/time-turner`,
  '/book-extract-long/a-true-gryffindor': `/writing-by-jk-rowling/the-sword-of-gryffindor`,
  '/book-extract-long/neville-longbottom-and-the-sorting-hat': `/writing-by-jk-rowling/the-sorting-hat`,
  '/book-extract-long/fred-weasley-gives-harry-the-marauders-map': `/writing-by-jk-rowling/the-marauders-map`,
  '/book-extract-long/a-marauders-youth': `/writing-by-jk-rowling/the-marauders-map`,
  '/book-extract-long/welcome-to-the-knight-bus': `/writing-by-jk-rowling/the-knight-bus`,
  '/book-extract-long/nymphadora-tonks-turns-up-at-hogwarts': `/writing-by-jk-rowling/secret-keeper`,
  '/book-extract-long/mcgonagalls-introduction': `/writing-by-jk-rowling/professor-mcgonagall`,
  '/book-extract-long/platform-puzzler': `/writing-by-jk-rowling/platform-nine-and-three-quarters`,
  '/book-extract-long/ron-leaves': `/writing-by-jk-rowling/peeves`,
  '/book-extract-long/remus-lupin-teaches-harry-about-patronus-charm': `/writing-by-jk-rowling/patronus-charm`,
  '/book-extract-long/care-of-magical-creatures-owl': `/writing-by-jk-rowling/owls`,
  '/book-extract-long/escape-from-privet-drive': `/writing-by-jk-rowling/number-four-privet-drive`,
  '/book-extract-long/cruciatus-curse-in-the-ministry-of-magic': `/writing-by-jk-rowling/ministers-for-magic`,
  '/book-extract-long/dudleys-birthday': `/writing-by-jk-rowling/marge-dursley`,
  '/book-extract-long/aunt-marges-mistake': `/writing-by-jk-rowling/marge-dursley`,
  '/book-extract-long/harry-in-the-ravenclaw-common-room': `/writing-by-jk-rowling/hufflepuff-common-room`,
  '/book-extract-long/meeting-malfoy': `/writing-by-jk-rowling/draco-malfoy`,
  '/book-extract-long/draco-malfoy-buckbeak-attack': `/writing-by-jk-rowling/draco-malfoy`,
  '/book-extract-long/revenge-for-umbridge': `/writing-by-jk-rowling/dolores-umbridge`,
  '/book-extract-long/killing-curse-in-defence-against-dark-arts': `/writing-by-jk-rowling/curses-and-counter-curses`,
  '/book-extract-long/the-basilisk-is-defeated': `/writing-by-jk-rowling/chamber-of-secrets`,
  '/book-extract-long/pesky-pixies': `/writing-by-jk-rowling/chamber-of-secrets`,
  '/book-extract-long/basilisk-discovery': `/writing-by-jk-rowling/chamber-of-secrets`,
  '/book-extract-long/mrs-weasleys-boggart': `/writing-by-jk-rowling/boggart`,
  '/book-extract-long/i-spy': `/writing-by-jk-rowling/azkaban`,
  '/book-extract-long/harry-takes-a-ride': `/writing-by-jk-rowling/azkaban`,
  '/book-extract-long/what-is-a-kelpie': '/writing-by-jk-rowling',
  '/book-extract-long/u-no-poo': '/writing-by-jk-rowling',
  '/book-extract-long/the-potter-memorial': '/writing-by-jk-rowling',
  '/book-extract-long/the-patients-of-st-mungos': '/writing-by-jk-rowling',
  '/book-extract-long/stan-shunpike-suspected': '/writing-by-jk-rowling',
  '/book-extract-long/learning-to-apparate': '/writing-by-jk-rowling',
  '/book-extract-long/i-was-ready': '/writing-by-jk-rowling',
  '/book-extract-long/harry-sneaks-into-hogsmeade': '/writing-by-jk-rowling',
  '/book-extract-long/hallows-or-horcruxes': '/writing-by-jk-rowling',
  '/book-extract-long/first-encounters': '/writing-by-jk-rowling',
  '/book-extract-long/battle-in-the-bathroom': '/writing-by-jk-rowling',
  '/book-extract-long/modestys-rhyme': `/news/fantastic-beasts-and-where-to-find-them-screenplay-announcement`,
  '/book-extract-long/what-is-a-crup': `/news/fantastic-beasts-and-where-to-find-them-logo-revealed`,
  '/book-extract-long/the-ragged-veil': `/features/why-we-wish-harry-and-sirius-had-more-time-together`,
  '/book-extract-long/a-very-important-ceremony': `/features/why-the-philosophers-stone-sorting-ceremony-was-basically-the-best-one`,
  '/book-extract-long/what-is-a-niffler': `/features/why-the-niffler-deserves-his-own-spin-off-series`,
  '/book-extract-long/saving-hagrids-hut': `/features/why-hagrids-hut-is-the-best-place-to-live`,
  '/book-extract-long/what-is-a-bowtruckle': `/features/which-fantastic-beast-is-right-for-you`,
  '/book-extract-long/hagrids-tale': `/features/what-we-learned-from-rubeus-hagrid`,
  '/book-extract-long/lilys-letter': `/features/unsung-heroes-of-harry-potter-stories-lily-potter`,
  '/book-extract-long/transforming-into-goyle': `/features/top-five-stupidest-things-crabbe-and-goyle-ever-did`,
  '/book-extract-long/voldemort-past-present-and-future': `/features/things-you-may-not-have-noticed-about-lord-voldemort`,
  '/book-extract-long/the-hogwarts-song': `/features/the-wizarding-songbook-hogwarts-greatest-hits`,
  '/book-extract-long/a-change-of-tune': `/features/the-sorting-hat`,
  '/book-extract-long/flamel-found': `/features/the-real-nicolas-flamel-and-the-philosophers-stone`,
  '/book-extract-long/flamel-embraces-death': `/features/the-real-nicolas-flamel-and-the-philosophers-stone`,
  '/book-extract-long/im-not-dancing': `/features/the-most-cringeworthy-moments-in-harry-potter`,
  '/book-extract-long/where-our-voices-sound': `/features/the-goblet-of-fire`,
  '/book-extract-long/weatherby': `/features/the-goblet-of-fire`,
  '/book-extract-long/wand-connection-explained': `/features/the-goblet-of-fire`,
  '/book-extract-long/the-sphinxs-riddle': `/features/the-goblet-of-fire`,
  '/book-extract-long/spew': `/features/the-goblet-of-fire`,
  '/book-extract-long/furnunculus': `/features/the-goblet-of-fire`,
  '/book-extract-long/first-impressions': `/features/the-goblet-of-fire`,
  '/book-extract-long/rowena-and-helena': `/features/the-deathly-hallows`,
  '/book-extract-long/return-to-dumbledores-office': `/features/the-deathly-hallows`,
  '/book-extract-long/protecting-the-tent': `/features/the-deathly-hallows`,
  '/book-extract-long/prisoners-taken-to-the-manor': `/features/the-deathly-hallows`,
  '/book-extract-long/an-unexpected-shift': `/features/the-deathly-hallows`,
  '/book-extract-long/guest-of-the-death-eaters': `/features/the-5-death-eaters-youll-never-forget`,
  '/book-extract-long/he-stabbed-me': `/features/terrible-tale-behind-the-bloody-barons-bloodiness`,
  '/book-extract-long/a-tale-of-four-founders': `/features/stories-of-the-hogwarts-founders`,
  '/book-extract-long/bellatrixs-endeavours': `/features/pottermore-bellatrix-lestrange-infographic`,
  '/book-extract-long/mars-is-bright-tonight': `/features/philosophers-stone`,
  '/book-extract-long/harrys-new-broom': `/features/philosophers-stone`,
  '/book-extract-long/first-years-sorted': `/features/philosophers-stone`,
  '/book-extract-long/first-flying-lesson': `/features/philosophers-stone`,
  '/book-extract-long/dumbledores-answers': `/features/philosophers-stone`,
  '/book-extract-long/a-curious-wand': `/features/philosophers-stone`,
  '/book-extract-long/wingardium-leviosa': `/features/magical-spells-we-first-learned-in-philosophers-stone`,
  '/book-extract-long/destroying-the-ring': `/features/magical-properties-and-defences-of-voldemorts-horcruxes`,
  '/book-extract-long/lavender-brown-and-her-rabbit': `/features/lavender-brown-infographic`,
  '/book-extract-long/rita-interviews-harry': `/features/in-defence-of-rita-skeeter`,
  '/book-extract-long/kreachers-tale': `/features/in-defence-of-kreacher`,
  '/book-extract-long/hello-kreacher': `/features/in-defence-of-kreacher`,
  '/book-extract-long/dead-or-alive': `/features/important-gestures-of-motherly-love`,
  '/book-extract-long/lunas-bedroom': `/features/how-to-live-like-luna-lovegood`,
  '/book-extract-long/rons-ultimate-chess-match': `/features/how-ron-weasleys-wizard-chess-match-showed-his-true-potential`,
  '/book-extract-long/rescuing-ron': `/features/how-ron-weasley-grew-from-sidekick-to-superman`,
  '/book-extract-long/ballad-of-nearly-headless-nick': `/features/halloween-party-tips-from-nearly-headless-nick`,
  '/book-extract-long/placating-fluffy': `/features/fluffy-and-other-creatures-inspired-by-classical-mythology`,
  '/book-extract-long/a-lesson-on-the-hallows': `/features/everything-you-may-have-forgotten-from-deathly-hallows-epilogue`,
  '/book-extract-long/welcome-to-the-house-of-black': `/features/everything-we-know-about-the-traitors-of-the-house-of-black`,
  '/book-extract-long/the-broken-wand': `/features/everything-we-know-about-the-elder-wand`,
  '/book-extract-long/arrival-at-hogwarts': `/features/everything-a-first-year-should-know-about-hogwarts`,
  '/book-extract-long/into-the-alley': `/features/diagon-alley`,
  '/book-extract-long/the-snitch-opens-at-the-close': `/features/designing-the-golden-snitch`,
  '/book-extract-long/look-who-it-is': `/features/chapter-that-made-us-fall-in-love-with-severus-snape`,
  '/book-extract-long/rons-return': `/features/chapter-that-made-us-fall-in-love-with-ron-weasley`,
  '/book-extract-long/Ron-is-reunited-with-Harry-and-Hermione': `/features/chapter-that-made-us-fall-in-love-with-ron-weasley`,
  '/book-extract-long/mollys-guidance': `/features/chapter-that-made-us-fall-in-love-with-molly-weasley`,
  '/book-extract-long/what-is-a-wampus': `/features/care-of-magical-creatures-illustrations-introducing-new-fantastic-beasts`,
  '/book-extract-long/thunderbirds-fantastic-beasts-introduction': `/features/care-of-magical-creatures-illustrations-introducing-new-fantastic-beasts`,
  '/book-extract-long/harry-meets-the-goblins': `/features/bringing-goblins-of-gringotts-to-life`,
  '/book-extract-long/the-dark-lord-rises': `/features/analysing-tom-riddles-choices`,
  '/book-extract-long/first-occlumency-lesson': `/features/a-guide-to-occlumency`,
  '/book-extract-long/snape-targets-harry': `/features/a-complex-man-exploring-the-light-and-shade-of-severus-snape`,
  '/book-extract-long/harry-and-ginnys-big-moment': `/features/8-adorable-moments-between-harry-and-ginny`,
  '/book-extract-long': `/discover/books`,
  '/collection': '/',
  '/collection/characters': `/news/harry-potter-characters-kawaii-designs`,
  '/collection/pottermore-quiz-collection': '/quiz',
  '/collection/writing-by.jkrowling': `/news/pottermore-reveals-new-writing-by-jk-rowling-on-macusa`,
  '/collection/The-sad-history-of-Remus-Lupin': `/writing-by-jk-rowling/remus-lupin`,
  '/collection/the-potter-family': `/writing-by-jk-rowling/the-potter-family`,
  '/collection/regular-wizarding-life': `/features/which-wizarding-world-home-would-you-live-in`,
  '/collection/in-defence-of': `/features/in-defence-of-kreacher`,
  '/collection/harry-potter-prisoner-of-azkaban-collection': `/writing-by-jk-rowling/azkaban`,
  '/collection/harry-potter-philosophers-stone-collection': `/features/philosophers-stone`,
  '/collection/harry-potter-goblet-of-fire-collection': `/features/the-goblet-of-fire`,
  '/collection/harry-potter-chamber-of-secrets-collection': `/writing-by-jk-rowling/chamber-of-secrets`,
  '/collection/gaunt-family': `/features/gaunt-family-tree`,
  '/collection/fantastic-beasts-characters': `/features/fantastic-beasts`,
  '/collection/fantastic-beasts-behind-the-scenes': `/features/behind-the-scenes-fantastic-beasts-nifflers`,
  '/collection/families': `/writing-by-jk-rowling/familiars`,
  '/collection/dragons-of-the-wizarding-world': `/features/dragons-of-the-wizarding-world-hungarian-horntail`,
  '/collection/creatures-fantastic-beasts': `/features/fantastic-beasts`,
  '/collection/black-family': `/features/black-family-tree`,
  '/collection/all-about-gryffindor': '/collections/gryffindor',
  '/collection/all-about-ravenclaw': '/collections/ravenclaw',
  '/collection/all-about-hufflepuff': '/collections/hufflepuff',
  '/collection/all-about-slytherin': '/collections/slytherin',
  '/collections': '/',
  '/cursed-child': '/discover/on-stage',
  '/discover/films/fantastic-beasts-the-crimes-of-grindlewald':
    '/discover/films/fantastic-beasts-the-crimes-of-grindelwald',
  '/discover/books/fantastic-beasts-the-crimes-of-grindlewald':
    '/discover/books/fantastic-beasts-the-crimes-of-grindelwald',
  '/explore-the-story': '/features',
  '/explore-the-story/dobby': `/features/5-friendship-lessons-we-learned-from-dobby-the-house-elf`,
  '/explore-the-story/rowena-ravenclaw': `/features/stories-of-the-hogwarts-founders`,
  '/explore-the-story/regulus-black': `/features/unsung-heroes-of-harry-potter-stories-regulus-black`,
  '/explore-the-story/neville-longbottom': `/features/why-neville-longbottom-story-always-deserved-a-happy-ending`,
  '/explore-the-story/moaning-myrtle': `/features/unsung-heroes-moaning-myrtle`,
  '/explore-the-story/lord-voldemort': `/features/things-you-may-not-have-noticed-about-lord-voldemort`,
  '/explore-the-story/hogsmeade': `/features/behind-the-scenes-hogsmeade`,
  '/explore-the-story/helga-hufflepuff': `/features/stories-of-the-hogwarts-founders`,
  '/explore-the-story/cedric-diggory': `/features/unsung-heroes-of-harry-potter-stories-cedric-diggory`,
  '/explore-the-story/bellatrix-lestrange': `/features/every-time-bellatrix-lestrange-gave-us-nightmares`,
  '/explore-the-story/thestrals': `/writing-by-jk-rowling/thestrals`,
  '/explore-the-story/remus-lupin': `/writing-by-jk-rowling/remus-lupin`,
  '/explore-the-story/the-sorting-hat': `/news/discover-your-hogwarts-house-on-wizarding-world`,
  '/fantastic-beasts': '/discover/films',
  '/pins': 'https://explore.wizardingworld.com/pins',
  '/ladygrieve': '/store?path=/products/limited-edition-nearly-headless-nick-enamel-pin',
  '/sorting': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/sorting-hat`,
  '/writing-by-jk-rowling/wand-lengths-and-flexibilit':
    '/writing-by-jk-rowling/wand-lengths-and-flexibility',
  '/outcome': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/passport`,
  '/features/back-to-hogwarts-lego-harry-potter':
    '/features/back-to-hogwarts-activities-platform-nine-and-three-quarters',
  '/account/confirm-age': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/register/age-gate`,
  '/ilvermorny-sorting': '/writing-by-jk-rowling/ilvermorny',
  '/account/login': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/login`,
  '/features/the-harry-potter-presents-quiz': '/quiz/the-harry-potter-presents-quiz',
  '/passport': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/passport`,
  '/.well-known/change-password': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/forgot-password`,
  '/hogwarts': '/collections/welcome-to-hogwarts',
  '/fanclub': '/harry-potter-fan-club',
  '/gold-benefits': '/harry-potter-fan-club',
  '/about-gold': '/harry-potter-fan-club',
  '/about-gold-perks': '/harry-potter-fan-club',
  '/about-passport': '/harry-potter-fan-club',
  '/about-gold-gifts': '/harry-potter-fan-club',
  '/about-gold-unavailable': '/harry-potter-fan-club',
  '/harry-potter-fan-club-tiers': '/harry-potter-fan-club',
  '/harry-potter-fan-club-gold-year-two': '/harry-potter-fan-club',
  '/about-the-fan-club': '/harry-potter-fan-club',
  '/membership': GOLD_SUNSET_FAQ,
  '/membership/about-you': GOLD_SUNSET_FAQ,
  '/membership/age-gate': GOLD_SUNSET_FAQ,
  '/membership/payment-details': GOLD_SUNSET_FAQ,
  '/membership/success': GOLD_SUNSET_FAQ,
  '/membership/young-visitors': GOLD_SUNSET_FAQ,
  '/membership/payment-error': GOLD_SUNSET_FAQ,
  '/membership/active-gold': GOLD_SUNSET_FAQ,
  '/membership/not-available': GOLD_SUNSET_FAQ,
  '/redeem/year-2': GOLD_SUNSET_FAQ,
  '/redeem/:code': GOLD_SUNSET_FAQ,
  '/redeem-gold/:code': GOLD_SUNSET_FAQ,
  '/chapters': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/chapters/harry-potter-at-home-chapter-readings-all-seventeen-chapters': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-boy-who-lived': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-vanishing-glass': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-letters-from-no-one': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-keeper-of-the-keys': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-diagon-alley': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/chapters/reading-the-journey-from-platform-nine-and-three-quarters': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-sorting-hat': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-potions-master': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-midnight-duel': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-halloween': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/chapters/reading-quidditch': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/chapters/reading-the-mirror-of-erised': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-nicolas-flamel': '/features/trip-down-memory-lane-harry-potter-at-home-video',
  '/chapters/reading-norbert-the-norwegian-ridgeback': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-forbidden-forest': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-through-the-trapdoor': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/chapters/reading-the-man-with-two-faces': `/features/trip-down-memory-lane-harry-potter-at-home-video`,
  '/collections/harry-potter-at-home': '/collections/starting-harry-potter',
  '/chapters/listen-new-journeys-to-hogwarts-audio-experience': `/features/listen-new-journeys-to-hogwarts-audio-experience`,
  '/sorting-hat/question-1': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/sorting-hat/question-1`,
  '/profile/wand-intro': `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/wand/question-1`,
  '/my-wizarding-world/:shareId': '/',
  '/discover/on-stage': '/discover-on-stage',
  '/discover/experiences': '/discover-experiences',
  '/discover/portkey-games': '/discover-portkey-games',
  '/discover/portkey-games/harry-potter-hogwarts-legacy':
    '/discover-portkey-games/portkey-games/hogwarts-legacy',
  '/discover/portkey-games/harry-potter-hogwarts-mystery':
    '/discover-portkey-games/portkey-games/hogwarts-mystery',
  '/discover/portkey-games/harry-potter-puzzles-and-spells':
    '/discover-portkey-games/portkey-games/harry-potter-puzzles-and-spells',
  '/discover/portkey-games/harry-potter-magic-awakened':
    '/discover-portkey-games/portkey-games/harry-potter-magic-awakened',
  '/discover/experiences/warner-bros-studio-tour-london-the-making-of-harry-potter':
    '/discover-experiences/experiences/warner-bros-studio-tour-london-the-making-of-harry-potter',
  '/discover/experiences/the-wizarding-world-of-harry-potter-universal-studios-hollywood':
    '/discover-experiences/experiences/the-wizarding-world-of-harry-potter-universal-studios-hollywood',
  '/discover/experiences/the-wizarding-world-of-harry-potter-universal-orlando-resort':
    '/discover-experiences/experiences/the-wizarding-world-of-harry-potter-universal-orlando-resort',
  '/discover/experiences/the-wizarding-world-of-harry-potter-universal-studios-japan':
    '/discover-experiences/experiences/the-wizarding-world-of-harry-potter-universal-studios-japan',
  '/discover/experiences/the-harry-potter-film-concert-series':
    '/discover-experiences/experiences/the-harry-potter-film-concert-series',
  '/discover/experiences/harry-potter-the-exhibition':
    '/discover-experiences/experiences/harry-potter-the-exhibition',
  '/discover/experiences/universal-beijing-resort':
    '/discover-experiences/experiences/the-wizarding-world-of-harry-potter-universal-beijing-resort',
  '/discover/experiences/harry-potter-ny':
    '/discover-experiences/experiences/harry-potter-new-york',
  '/discover/experiences/platform-934-shop':
    '/discover-experiences/experiences/the-harry-potter-shop-at-platform-934',
  '/discover/experiences/harry-potter-photographic-exhibition': '/discover-experiences',
  '/discover/experiences/harry-potter-forbidden-forest':
    '/discover-experiences/experiences/harry-potter-a-forbidden-forest-experience',
  '/discover/experiences/fantastic-beasts-the-wonder-of-nature':
    '/discover-experiences/experiences/fantastic-beasts-the-wonder-of-nature',
  '/discover/experiences/harry-potter-magic-at-play':
    '/discover-experiences/experiences/harry-potter-magic-at-play',
  '/discover/experiences/harry-potter-yule-ball-celebration':
    '/discover-experiences/experiences/harry-potter-a-yule-ball-celebration',
  '/discover/experiences/studio-tour-tokyo':
    '/discover-experiences/experiences/warner-bros-studio-tour-tokyo-the-making-of-harry-potter',
  '/discover/experiences/wizarding-world-festival': '/discover-experiences',
};

module.exports = redirectMap;
